import React from 'react';
import SEO from '../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { useTranslation } from 'react-i18next';

const RedirectPage = ({ location, pageContext }) => {
  const { t } = useTranslation();
  return (
    <>
      <SEO
        title={t('Redirect')}
        lang={pageContext.lang}
        style={[
          {
            cssText: `
                        html {
                            box-sizing: border-box;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                        *, *::before, *::after {
                            box-sizing: inherit;
                        }
                        strong, b {
                            font-weight: 700;
                        }
                        body {
                            color: rgba(0, 0, 0, 0.87);
                            margin: 0;
                            font-size: 0.875rem;
                            font-family: "Open Sans",sans-serif;
                            font-weight: 400;
                            line-height: 1.43;
                            background-color: #0f1118;
                        }
                        @media print {
                            body {
                                background-color: #fff;
                            }
                        }
                        body::backdrop {
                            background-color: #0f1118;
                        }
                      `,
          },
        ]}
        meta={[
          {
            name: `robots`,
            content: 'noindex,nofollow',
          },
        ]}
      />
      <ResetCSS />
    </>
  );
};

export default RedirectPage;
